import React, { Component } from 'react';
import PropTypes from 'prop-types';

const OptionField = ({ label, value}) =>
  <option
    value={value}
  >
    {label}
  </option>;

OptionField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default OptionField;
