import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { api } from '../utils/api';
import R from 'ramda';

const titleFor = attachment => attachment.title ? attachment.title : attachment.fileFileName;

class MultiAttachmentInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attachmentInputs: [
        {
          title: '',
        }
      ],
      attachments: props.attachments,
    };
  }

  addInput = () => {
    const newAttachmentInputs = R.append({ title: '' }, this.state.attachmentInputs);
    this.setState({ attachmentInputs: newAttachmentInputs });
  }

  removeInput = (index) => {
    const newAttachmentInputs = R.remove(index, 1, this.state.attachmentInputs)

    this.setState({ attachmentInputs: newAttachmentInputs });
  }

  deleteAttachment = (id) => {
    api().delete(`/admin/attachments/${id}.json`)
      .then(() => {
        const index = R.findIndex(R.propEq('id', id))(this.state.attachments);
        const newAttachments = R.remove(index, 1, this.state.attachments);
        this.setState({ attachments: newAttachments });
      });
  }

  render() {
    const { attachments } = this.state;

    return (
      <div>
        {attachments.length > 0 && attachments.map((attachment, i) => (
          <div
            key={i}
            style={{ display: "flex", marginBottom: '20px' }}
          >
            <div>{titleFor(attachment)}</div>
            <div style={{ textAlign: 'right', width: '100%' }}>
              <span onClick={() => this.deleteAttachment(attachment.id)}><a role="button">Remove</a></span>
            </div>
          </div>
        ))}
        {this.state.attachmentInputs.map((attachment, i) => (
          <div
            key={i}
            className="input file optional resource_attachments_file"
            style={{ display: "flex" }}
          >
            <input
              type="text"
              name={`resource[attachments_attributes][${i+1}][title]`}
              id={`resource_attachments_attributes_${i+1}_title`}
              placeholder="Title"
              style={{ marginRight: '20px' }}
            />
            <input
              className="file optional"
              type="file"
              name={`resource[attachments_attributes][${i+1}][file]`}
              id={`resource_attachments_attributes_${i+1}_file`}
            />
          </div>
        ))}
        <div className="margin-bottom-30">
          <a role="button" onClick={this.addInput}>Add Another File +</a>
        </div>
      </div>
    );
  }
}

MultiAttachmentInput.propTypes = {
  attachments: PropTypes.instanceOf(Array).isRequired,
}

export default MultiAttachmentInput;
