import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ToggleArrow extends PureComponent {
  handleClick = () => {
    const { resource, direction, onMove } = this.props;

    if (direction === 'up') {
      onMove(resource, 'up');
    } else if (direction === 'down') {
      onMove(resource, 'down');
    }
  }

  render() {
    const { direction } = this.props;

    return (
      <span>
        <a
          className={`move-${direction}`}
          title={`Move ${direction}`}
          onClick={this.handleClick}
          style={{ cursor: 'pointer', paddingLeft: '7px', fontSize: '20px' }}
        >
          <i className={`fa fa-caret-${direction}`} aria-hidden="true"></i>
        </a>
      </span>
    );
  }
}

ToggleArrow.propTypes = {
  direction: PropTypes.string.isRequired,
  onMove: PropTypes.func.isRequired,
  resource: PropTypes.object.isRequired,
};

export default ToggleArrow;
