import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import FilterBar from './components/FilterBar';
import TopicCard from '../TopicCard';

class TopicsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      topics: props.topics,
    };
  }

  setSubCategory = (id) => {
    axios({
      method: 'GET',
      url: `/categories/${this.props.categoryId}/topics.json?sub_category_id=${id}`,
    })
      .then((response) => this.setState({ topics: response.data }))
  }

  render() {
    const { topics } = this.state;
    const { displayFilterBar, subCategories } = this.props;

    return (
      <div>
        {displayFilterBar && subCategories.length > 0 && (
          <FilterBar
            subCategories={subCategories}
            onSelect={this.setSubCategory}
          />
        )}

        <div className="container margin-top-50">
          <div className="row">
            {topics.map((topic) => (
              <div key={topic.id} className="col-md-6 col-lg-3">
                <TopicCard
                  topic={topic}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

TopicsView.defaultProps = {
  displayFilterBar: false,
};

TopicsView.propTypes = {
  categoryId: PropTypes.number,
  displayFilterBar: PropTypes.bool,
  subCategories: PropTypes.array,
  topics: PropTypes.array.isRequired,
};

export default TopicsView;

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('react-topics-view')
  const data = JSON.parse(node.getAttribute('data-camelized-props'))

  ReactDOM.render(
    <TopicsView {...data} />,
    node.appendChild(document.createElement('div')),
  )
})
