import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ResourceHeader extends Component {
  isActive = (pane) => pane === this.props.activePane;

  render() {
    const { displayDownloads, setActiveResourcePane } = this.props;

    return (
      <div className="resource-header">
        <a className={this.isActive('overview') ? 'active' : ''} onClick={() => setActiveResourcePane('overview')}>
          Overview
        </a>
        {displayDownloads &&
          <a className={this.isActive('downloads') ? 'active' : ''} onClick={() => setActiveResourcePane('downloads')}>
            Downloads
          </a>
        }
      </div>
    );
  }
}

ResourceHeader.propTypes = {
  activePane: PropTypes.string.isRequired,
  displayDownloads: PropTypes.bool.isRequired,
  setActiveResourcePane: PropTypes.func.isRequired,
};

export default ResourceHeader;
