import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AudioEmbed extends Component {
  render() {
    const { audio } = this.props;

    return (
      <div>
        <label>{audio.title}</label>
        <audio src={audio.url} style={{ width: '100%', marginBottom: '15px' }} controls /> 
      </div>
    );
  }
}

AudioEmbed.propTypes = {
  audio: PropTypes.object.isRequired,
};

export default AudioEmbed;
