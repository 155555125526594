import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import R from 'ramda';

import ToggleArrow from './components/ToggleArrow';

class AdminResourcesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: props.resources,
    };
  }

  handleMove = (resource, direction) => {
    const { resources } = this.state;
    const index = R.findIndex(R.propEq('id', resource.id))(resources);
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    const resourcesRemoved = R.remove(index, 1, resources);
    const newResources = R.insert(newIndex, resource, resourcesRemoved);
    const sortedResourceIds = R.pluck('id')(newResources);

    this.updatePosition(sortedResourceIds);
  }

  updatePosition = (resourceIds) => {
    $.ajax({
      type: 'PUT',
      dataType: 'json',
      data: { resource_ids: resourceIds },
      context: this,
      url: `/admin/topics/${this.props.resources[0].topicId}/bulk_position_update`,
      success: response => {
        this.setState({ resources: response });
      },
    })
  }

  deleteResource = (resource) => {
    $.ajax({
      type: 'DELETE',
      dataType: 'json',
      context: this,
      url: `/admin/topics/${resource.topicId}/resources/${resource.id}`,
      success: response => {
        const { resources } = this.props;
        const newResources = R.remove(
          R.findIndex(R.propEq('id', resource.id))(resources), 1, resources
        );
        this.setState({ resources: newResources });
      },
    })
  }

  render() {
    const { resources } = this.state;

    return (
      <div className="table">
        <div className="row table-header hidden-sm-down">
          <div className="col-md-3">
            <b>Title</b>
          </div>
          <div className="col-md-3">
            <b>Description</b>
          </div>
          <div className="col-md-2">
            <b>Video</b>
          </div>
          <div className="col-md-2">
            <b>Position</b>
          </div>
        </div>

        {resources.map((resource, index) => (
          <div className="row table-row" key={resource.id}>
            <div className="col-md-3">
              <b><a href={resource.resourceEditUrl}>{resource.title}</a></b>
            </div>
            <div className="col-md-3">
              {resource.description}
            </div>
            <div className="col-md-2">
              {resource.videoPresent}
            </div>
            <div className="col-md-2">
              {index !== 0 &&
                <ToggleArrow
                  direction="up"
                  onMove={this.handleMove}
                  resource={resource}
                />
              }
              {index !== resources.length - 1 &&
                <ToggleArrow
                  direction="down"
                  onMove={this.handleMove}
                  resource={resource}
                />
              }
            </div>
            <div className="col-md-2 text-right">
              <a href={resource.resourceEditUrl}>
                <div className="edit-button">
                  <i className="fa fa-pencil"></i>
                </div>
              </a>
              <div
                className="delete-button"
                onClick={() => { if(confirm('Delete this resource?')) {this.deleteResource(resource)}; }}
                style={{ cursor: 'pointer' }}
              >
                <i className="fa fa-trash"></i>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

AdminResourcesTable.propTypes = {
  resources: PropTypes.instanceOf(Array).isRequired,
};

export default AdminResourcesTable;

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('react-admin-resources-table')
  const data = JSON.parse(node.getAttribute('data-camelized-props'))

  ReactDOM.render(
    <AdminResourcesTable {...data} />,
    node.appendChild(document.createElement('div')),
  )
})
