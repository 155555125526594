import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import R from 'ramda';
import MediaQuery from 'react-responsive';

import AudioEmbed from './components/AudioEmbed';
import OptionField from '../OptionField';
import ResourceHeader from './components/ResourceHeader';
import ResourceRow from './components/ResourceRow';
import VideoEmbed from './components/VideoEmbed';

const ResourcesView = ({ resources, userId }) => {
  const [activeResource, setActiveResource] = useState(resources[0]);
  const [activeResourcePane, setActiveResourcePane] = useState('overview');
  const [menuSticky, setMenuSticky] = useState(false);

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollingElement.scrollTop;
    const shouldStick = scrollTop >= 80;

    setMenuSticky(shouldStick);
  }

  const handleSetActiveResource = (id) => {
    const resource_id = Number.isInteger(id) ? id : Number(id.target.value);
    const resource = R.find(R.propEq('id', resource_id))(resources);

    setActiveResource(resource);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  });

  const featuredAudio = activeResource.featuredAudio;
  const displayDownloads = activeResource.attachments.length > 0;
  const displayVideo = activeResource.videoUrl;
  const displayAudio = !displayVideo && featuredAudio;
  const menuClasses = menuSticky ? "resource-container fixed" : "resource-container";

  return (
    <div className="row">
      <div className="col-md-4">
        <MediaQuery query='(min-width: 768px)'>
          <div className={menuClasses}>
            <div className="resource-list">
              {resources.map((resource, index) => (
                <ResourceRow
                  key={resource.id}
                  activeResource={activeResource}
                  itemNumber={index + 1}
                  makeActive={handleSetActiveResource}
                  resource={resource}
                />
              ))}
            </div>
          </div>
        </MediaQuery>
        <MediaQuery query='(max-width: 768px)'>
          <select
            className="margin-bottom-30"
            onChange={handleSetActiveResource}
          >
            {resources.map((resource, index) => (
              <OptionField
                key={resource.id}
                label={resource.title}
                value={resource.id}
              />
            ))}
          </select>
        </MediaQuery>
      </div>

      <div className="col-md-8">
        {displayVideo &&
          <VideoEmbed
            resourceId={activeResource.id}
            url={activeResource.videoUrl}
            userId={userId}
          />
        }

        {displayAudio &&
          <AudioEmbed
            audio={featuredAudio}
          />
        }

        <div className="resource-body-container">
          <ResourceHeader
            activePane={activeResourcePane}
            displayDownloads={displayDownloads}
            setActiveResourcePane={setActiveResourcePane}
          />

          {activeResourcePane === 'overview' ? (
            <div
              className="resource-body"
              dangerouslySetInnerHTML={{ __html: activeResource.body }}
            />
          ) : (
            <div className="resource-body">
              <strong>Use these files to help support the training.</strong>
              <ol className="margin-top-20 padding-left-20">
                {activeResource.attachments.map((attachment) => (
                  <li>
                    <a href={attachment.url} target="_blank">
                      {attachment.title ? attachment.title : attachment.fileName} ({attachment.fileSize} kb)
                    </a>
                  </li>
                ))}
              </ol>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ResourcesView.defaultProps = {
  userId: null,
};

ResourcesView.propTypes = {
  resources: PropTypes.instanceOf(Array).isRequired,
  userId: PropTypes.number,
};

export default ResourcesView;

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('react-resources-view')
  const data = JSON.parse(node.getAttribute('data-camelized-props'))

  ReactDOM.render(
    <ResourcesView {...data} />,
    node.appendChild(document.createElement('div')),
  )
})
