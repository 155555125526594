import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { api } from '../utils/api';

import R from 'ramda';

import MultiAttachmentInput from './MultiAttachmentInput';

const titleFor = attachment => attachment.title ? attachment.title : attachment.fileFileName;

class ResourceAttachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: props.errors,
      feature: props.feature,
      videoUpload: props.feature ? false : true,
      videoUrl: props.videoUrl,
    };
  }

  deleteAttachment = (id) => {
    api().delete(`/admin/attachments/${id}.json`)
      .then(() => this.setState({ feature: null }));
  };

  toggleVideoUpload = value => this.setState({ videoUpload: value === 'video' });

  setVideoUrl = e => this.setState({ videoUrl: e.target.value });

  render() {
    const { feature, videoUpload, videoUrl } = this.state;

    return (
      <div>
        <input
          type="radio"
          name="featureType"
          id="video"
          onClick={() => this.toggleVideoUpload('video')}
          checked={videoUpload}
          defaultChecked
        />
        <label htmlFor="video" style={{ marginLeft: '5px', marginRight: '10px' }}>Feature Video</label>
        <input
          type="radio"
          name="featureType"
          id="audio"
          onClick={() => this.toggleVideoUpload('audio')}
          checked={!videoUpload}
        />
        <label htmlFor="audio" style={{ marginLeft: '5px' }}>Feature Audio (mp3)</label>

        {videoUpload &&
          <div className="input string optional resource_video_url field_with_hint">
            <input
              className="string optional"
              placeholder="Optional vimeo url to be embedded on the page..."
              type="text"
              name="resource[video_url]"
              id="resource_video_url"
              value={videoUrl}
              onChange={this.setVideoUrl}
            />
          </div>
        }

        {!videoUpload && feature &&
          <div
            style={{ display: "flex", marginBottom: '20px' }}
          >
            <div style={{ width: '100%' }}>{titleFor(feature)}</div>
            <div style={{ textAlign: 'right' }}>
              <span onClick={() => this.deleteAttachment(feature.id)}><a role="button">Remove</a></span>
            </div>
          </div>
        }

        {!videoUpload && !feature &&
          <div
            key={0}
            className="input file optional resource_attachments_file"
            style={{ display: "flex" }}
          >
            <input
              type="text"
              name={`resource[attachments_attributes][0][title]`}
              id={`resource_attachments_attributes_0_title`}
              placeholder="Title"
              style={{ marginRight: '20px' }}
            />
            <input
              className="file optional"
              type="file"
              name={`resource[attachments_attributes][0][file]`}
              id={`resource_attachments_attributes_0_file`}
            />
            <input
              name={`resource[attachments_attributes][0][feature]`}
              id={`resource_attachments_attributes_0_feature`}
              value={true}
              hidden
            />
          </div>
        }

        <label>Attachments</label>
        <MultiAttachmentInput
          attachments={this.props.attachments}
        />
      </div>
    );
  }
}

ResourceAttachments.propTypes = {
  videoUrl: PropTypes.string,
  attachments: PropTypes.instanceOf(Array).isRequired,
  feature: PropTypes.instanceOf(Object),
}

export default ResourceAttachments;

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('react-resource-attachments')
  const data = JSON.parse(node.getAttribute('data-camelized-props'))

  ReactDOM.render(
    <ResourceAttachments {...data} />,
    node.appendChild(document.createElement('div')),
  )
})
