import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TopicCard extends Component {
  render() {
    const { topic } = this.props;

    return (
      <div className="topic-card">
        <a href={topic.topicUrl}>
          <div className="topic-img">
            <div className="content">
              <img src={topic.imageUrl} alt={topic.title} className="img-fluid" />
            </div>
          </div>
          <div className="topic-desc">
            <h5>{topic.title.toUpperCase()}</h5>
            <h6>{topic.categoryTitle.toUpperCase()}</h6>
          </div>
        </a>
      </div>
    );
  }
}

TopicCard.propTypes = {
  topic: PropTypes.instanceOf(Object).isRequired,
};

export default TopicCard;
