import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ResourceRow extends Component {
  constructor(props) {
    super(props);
  }

  isActiveResource = (resource) => {
    return resource === this.props.activeResource;
  }

  render() {
    const { itemNumber, makeActive, resource } = this.props;
    const listItemClasses = this.isActiveResource(resource) ? 'list-item active' : 'list-item';

    return (
      <div
        className={listItemClasses}
        onClick={() => makeActive(resource.id)} 
      >
        <p style={{ display: 'table-cell', verticalAlign: 'middle' }}>
          <span style={{ marginRight: '15px' }}>{itemNumber}</span>
          {resource.title}
          <i className="fa fa-angle-right float-right"></i>
        </p>
      </div>
    );
  }
}

ResourceRow.propTypes = {
  activeResource: PropTypes.instanceOf(Object).isRequired,
  itemNumber: PropTypes.number.isRequired,
  makeActive: PropTypes.func.isRequired,
  resource: PropTypes.instanceOf(Object).isRequired,
};

export default ResourceRow;
