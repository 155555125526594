import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/vimeo';

import { api } from '../../../utils/api';
import useUpdateEffect from '../../../hooks/useUpdateEffect';

const VideoEmbed = ({ resourceId, url, userId }) => {
  const [progress, setProgress] = useState(0);

  const handleTimeUpdate = (event) => {
    if (userId == null) { return };

    const percentage = Math.round(event.played * 100);

    if (percentage > progress) {
      const url = `/users/${userId}/resources/${resourceId}`;

      api().post(url, { progress: percentage })
        .then(() => setProgress(percentage));
    };
  };

  return (
    <div className="video-embed-container">
      <ReactPlayer
        url={url}
        onProgress={(e) => handleTimeUpdate(e)}
        progressInterval={5000}
        controls
      />
    </div>
  );
};

VideoEmbed.defaultProps = {
  userId: null,
}

VideoEmbed.propTypes = {
  resourceId: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  userId: PropTypes.number,
};

export default VideoEmbed;
