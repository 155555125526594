import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FilterBar extends Component {
  handleOnSelect = (e) => {
    this.props.onSelect(e.target.value);
  }

  render() {
    const { onSelect, subCategories } = this.props;

    return (
      <div style={{ background: '#DDD', height: '100px', marginBottom: '50px' }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-3 offset-sm-2" style={{ paddingTop: '40px' }}>
              <h4>RESOURCE FILTER:</h4>
            </div>
            <div className="col-sm-5" style={{ paddingTop: '30px' }}>
              <select onChange={this.handleOnSelect}>
                <option value="">All Resources</option>
                {subCategories.map((subCategory) => (
                  <option
                    key={subCategory.id}
                    value={subCategory.id}
                  >
                    {subCategory.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FilterBar.propTypes = {
  onSelect: PropTypes.func.isRequired,
  subCategories: PropTypes.array,
};

export default FilterBar;
